<template>
  <section class="order-list">
    <h3 v-if="orders.list.length" class="order-list__title">{{ orders.title }}</h3>
    <div v-if="orders.list.length" :class="`order-list__list-wrap order-list__list-wrap_${type}`"
         @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">
      <div :class="`order-list__list order-list__list_${type}`">
        <template v-if="type !== 'prev'">
          <article v-for="(order, index) in orders.list" :data-index="index"
                   :class="`order-list__item order-list__item_${type}`"
                   :id="`order-id-${order.id}`">
            <div>
              <h4 class="order-list__item__title">Бронирование № {{ order.id }}</h4>
              <h4 class="order-list__item__title">Дом “{{ order.house.title }}”</h4>
            </div>
            <div class="order-list__item__text">
              <p v-if="+order.is_paid === 2">Вы оплатили проживание полностью: {{ order.totalPrice }}₽ за
                {{ getDeclination(order.totalDays, 'сутки', 'суток', 'суток') }}</p>
              <div v-else-if="order?.pay?.price">
                <p>Вы внесли предоплату {{ Math.floor(order?.pay?.price) }}₽</p>
                <p>К доплате {{ Math.floor(order?.totalPrice - order?.pay?.price) }}₽</p>
              </div>
              <p v-if="type === 'next'" class="link_orange" @click="$parent.$parent.$refs.modal.openModal('remove', order)">Отменить или изменить поездку</p>
              <p v-else-if="type === 'curr'" class="button_orange order-list__item__text__button" @click="$parent.$parent.$refs.modal.openModal('pay', order)">Оплатить</p>
            </div>
            <div class="order-list__item__content">
              <img v-if="order.house.img" class="order-list__item__content__img" :src="order.house.img.url" alt="home.img">
              <p>{{ getPrettyDate(new Date(order.from)) }} - {{ getPrettyDate(new Date(order.to)) }}</p>
              <p>{{ order.totalDays }} суток / {{ order.totalPrice }} ₽</p>
            </div>
          </article>
        </template>

        <template v-else>
          <article v-for="(order, index) in orders.list" :data-index="index" :id="`order-id-${order.id}`"
                   :class="`order-list__item_${type}`">
            <h4 :class="`order-list__item__title order-list__item__title_${type}`">Бронирование № {{ order.id }}</h4>
            <img v-if="order.house.img" class="order-list__item__content__img_prev" :src="order.house.img.url" alt="home.img">
            <h4 :class="`order-list__item__title order-list__item__title_${type}`">Дом “{{ order.house.title }}”</h4>
            <div :class="`order-list__item__content order-list__item__content_${type}`">
              <p>{{ getPrettyDate(new Date(order.from)) }} - {{ getPrettyDate(new Date(order.to)) }}</p>
              <p>{{ order.totalDays }} суток / {{ order.totalPrice }} ₽</p>
            </div>
          </article>
        </template>
      </div>
      <div v-if="orders.list.length > 1" class="order-list__buttons">
        <div class="order-list__buttons__links">
          <button :class="{'order-list__buttons__link': true, 'order-list__buttons__link_curr': +index === +currIndex}"
                  v-for="(order, index) in orders.list" @click="clickOrderId(index)"></button>
        </div>

        <div class="order-list__buttons__arrows">
          <button @click="clickOrderId('prev')" :disabled="currIndex <= 0">
            <img :src="require('@/assets/image/arrows/left-blue.svg')" alt="left-blue.svg">
          </button>
          <button @click="clickOrderId('next')" :disabled="currIndex >= orders.list.length - 1">
            <img :src="require('@/assets/image/arrows/right-blue.svg')" alt="right-blue.svg">
          </button>
        </div>
      </div>
    </div>
    <div v-else class="order-list__empty">
      <p class="order-list__empty__text">Сейчас у вас отсутствуют "{{ orders.title }}". <br> Давайте это исправим!</p>
      <a class="link_orange" href="/houses">Перейти к выбору дома</a>
    </div>
  </section>
</template>

<script>
export default {
  name: "OrderList",
  props: {
    type: String,
    orders: Object,
  },
  data() {
    return {
      currIndex: 0,
      touchX: 0,
      touchY: 0,
    }
  },
  methods: {
    clickOrderId(to) {
      const list = document.querySelector(this.containerId);
      const count = list.childElementCount;
      const item = list.firstElementChild;
      const listWidth = list.scrollWidth - this.getCssParam(list, 'padding-left');
      const itemWidth = -(item.clientWidth + this.getCssParam(list, 'gap'));
      let currPoint = this.getCssParam(list, 'left');
      let nextPoint = currPoint;

      if (to === 'next' || to === 'prev') {
        for (let i = 0; i < count; i++) {
          const startElemPoint = Math.floor(itemWidth * i);
          if (currPoint === startElemPoint) {
            this.currIndex = i;
            break;
          } else if (currPoint > startElemPoint) {
            this.currIndex = i;
            break;
          }
        }

        if (to === 'next') {
          if (+count - 1 !== +this.currIndex) {
            nextPoint = Math.floor(itemWidth * (this.currIndex += 1));
          } else return;
        } else {
          if (0 !== +this.currIndex) {
            nextPoint = Math.floor(itemWidth * (this.currIndex -= 1));
          } else return;
        }
      } else if (typeof to === 'number') {
        this.currIndex = +to;
        nextPoint = Math.floor(itemWidth * to);
      }

      this.animateScrolling(list, currPoint, nextPoint);
    },
    getCssParam(elem, param) {
      return parseInt(window.getComputedStyle(elem).getPropertyValue(param))
    },
    animateScrolling(elem, from, to) {
      const step = from < to ? 20 : -20;
      let curr = from;
      const intervalId = setInterval(() => {
        if (Math.sign(step) > 0 && curr + step >= to) curr = to;
        else if (Math.sign(step) < 0 && curr + step <= to) curr = to;
        else curr += step;
        elem.style.left = `${curr}px`;
        if (curr === to) clearInterval(intervalId);
      }, 1)
    },
    getDeclination(val, one, two, three) {
      const valEnd = val % 10;
      let declination = one;
      if (+val >= 5 && +val <= 20 || valEnd === 0) {
        declination = three;
      } else if (valEnd >= 2 && valEnd <= 4) {
        declination = two;
      }
      return `${val} ${declination}`;
    },
    getPrettyDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      return `${day}.${month}.${year}`;
    },
    touchStart(e) {
      if (e.changedTouches.length === 1) {
        this.touchX = e.changedTouches[0].clientX;
        this.touchY = e.changedTouches[0].clientY;
      }
    },
    touchMove(e) {
      const {clientX, clientY} = e.changedTouches[0];
      if (e.cancelable && Math.abs(this.touchX - clientX) > Math.abs(this.touchY - clientY)) e.preventDefault();
    },
    touchEnd(e) {
      const {clientX, clientY} = e.changedTouches[0];
      if (Math.abs(this.touchX - clientX) > Math.abs(this.touchY - clientY)) {
        if (clientX > this.touchX) this.clickOrderId('prev');
        else this.clickOrderId('next');
      }

      this.touchX = 0;
      this.touchY = 0;
    }
  },
  computed: {
    containerId() {
      return `.order-list__list_${this.type}`;
    },
  },
}
</script>


<style scoped lang="scss">
$padding: 152px;
.order-list {
  margin-bottom: 100px;

  &__title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 120%;
    color: #181C16;
    max-width: 1440px;
    padding: 0 $padding;
    margin: 0 auto 20px;
  }

  &__list {
    display: flex;
    gap: 16px;
    padding-right: $padding;
    padding-left: $padding;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    left: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    &-wrap {
      padding: 40px 0 40px;
      overflow: hidden;

      &_curr {
        background: #E3F4FF;
      }
    }
  }

  &__item {
    display: grid;
    grid-template-rows: minmax(10%, 50%) minmax(10%, 50%);
    grid-template-columns: 258px 222px;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 4px;

    &_next {
      border: 1px solid #D5D5D5;
    }

    &_prev {
      border: 1px solid #8ECEF9;
      padding: 16px;
    }

    &__title {
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #181C16;
      margin-bottom: 20px;

      &_prev {
        margin-bottom: 0;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;

      &__button {
        padding: 4px 12px;
      }
    }

    &__content {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;

      &_prev {
        margin-top: 16px;
      }

      &__img {
        width: 222px;
        height: 130px;
        object-fit: cover;
        margin-bottom: 16px;
        border-radius: 4px;

        &_prev {
          width: 193px;
          height: 115px;
          margin: 12px 0;
          object-fit: cover;
        }
      }

      & p {
        font-family: Tenor Sans, sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #181C16;

        &:last-child {
          margin-top: 4px;
        }
      }
    }
  }

  &__buttons {
    margin: 16px auto 0;
    max-width: 1440px;
    padding: 0 $padding;
    display: flex;
    justify-content: space-between;

    &__links {
      width: 655px;
      height: 4px;
      display: flex;
      background: #EFEFEF;
    }

    &__link {
      flex: 1;
      border: none;

      &_curr {
        background: #8ECEF9;
      }
    }

    &__arrows {
      display: flex;
      gap: 16px;
      justify-content: space-between;

      & button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border: 1px solid #FFFFFF;
        border-radius: 2px;
        background: #FFFFFF;
        cursor: pointer;
        transition: background-color 300ms, border-color 300ms;

        &:hover:enabled {
          background: #E3F4FF;
          border-color: #8ECEF9;
        }

        &:disabled {
          background: #EFEFEF;
          border-color: #EFEFEF;
          cursor: auto;
        }
      }
    }
  }

  &__empty {
    text-align: center;

    &__text {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #181C16;
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 1281px) {
  $padding: 64px;
  .order-list {
    &__title {
      padding: 0 $padding;
    }

    &__list {
      padding-right: $padding;
      padding-left: $padding;
    }

    &__buttons {
      padding: 0 $padding;
    }
  }
}

@media screen and (max-width: 1025px) {
  $padding: 50px;
  .order-list {
    &__title {
      padding: 0 $padding;
    }

    &__list {
      padding-right: $padding;
      padding-left: $padding;
    }

    &__buttons {
      padding: 0 $padding;

      &__links {
        width: 100%;
      }

      &__arrows {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  $padding: 16px;
  $width-container: 288px;
  .order-list {
    margin-bottom: 50px;

    &__title {
      font-size: 24px;
      line-height: 28px;
      padding: 0 $padding;
      margin-bottom: 10px;
    }

    &__list {
      padding-right: $padding;
      padding-left: $padding;

      &-wrap {
        padding: 20px 0;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: 20px 16px;
      max-width: $width-container;
      min-width: $width-container;

      &__title {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 16px;
        }
      }

      &__text {
        order: 1;

        & p:first-child {
          margin-bottom: 16px;
        }
      }

      &__content {
        margin-bottom: 20px;

        &_prev {
          margin-bottom: 0;
        }

        &__img {
          width: 256px;
          height: 150px;
          margin-bottom: 12px;
        }
      }
    }

    &__buttons {
      margin: 19px auto 0;
      padding: 0 $padding;
    }
  }
}
</style>

<template>
  <Skillet v-if="isLoading"></Skillet>
  <div v-else class="email-confirm">
    <p class="email-confirm__text">
      Смена электронной почты успешно выполнена!<br>
      Теперь вы можете продолжить бронировать поездки, осталось лишь выбрать даты
    </p>
    <router-link class="email-confirm__button button_orange" to="/houses">Забронировать</router-link>
    <p class="email-confirm__text">
      Рекомендуем указать свой номер телефона в
      <router-link class="link_orange email-confirm__link" to="/account">профиле</router-link>,
      <br class="email-confirm__text-br">
      так мы сможем оперативно связаться с вами в случае необходимости
    </p>
  </div>
</template>

<script>
import Skillet from "@/components/blocks/Skillet";
import {setUser} from "@/services/authService";
import {httpApi} from "@/helper/httpHelper";

export default {
  name: "EmailChanged",
  components: {Skillet},
  data() {
    return {
      isLoading: true,
      id: 0,
      token: '',
    }
  },
  mounted() {
    this.sendData();
  },
  methods: {
    sendData() {
      this.isLoading = true;

      httpApi.post(
        'change-email-finish',
        {
          id: this.$route.params.id,
          email: this.$route.params.email,
          token: this.$route.params.token,
        },
      )
        .then(res => {
          this.$store.commit('setIsAuth', true);
          const {id, name, email, phone, avatar, token} = res.data;
          setUser(id, name, email, phone, avatar, token);
        })
        .catch(() => {
          this.$router.push('/');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
}
</script>

<style scoped lang="scss">
$fontSize: 24px;
$lineHeight: 28px;

.email-confirm {
  max-width: 1440px;
  margin: 20px auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  &__text {
    font-family: Raleway, sans-serif;
    font-weight: normal;
    font-size: $fontSize;
    line-height: $lineHeight;
    text-align: center;
    color: #181C16;
  }

  &__link {
    font-size: $fontSize;
    line-height: $lineHeight;
  }

  &__button {
    padding: 10px 16px;
  }
}

@media screen and (max-width: 769px) {
  $fontSize: 18px;
  $lineHeight: 150%;
  .email-confirm {
    padding: 20px;
    gap: 20px;

    &__text {
      font-size: $fontSize;
      line-height: $lineHeight;

      &-br {
        display: none;
      }
    }

    &__link {
      font-size: $fontSize;
      line-height: $lineHeight;
    }
  }
}

@media screen and (max-width: 481px) {
  $fontSize: 14px;
  .email-confirm {
    padding: 10px;
    gap: 20px;

    &__text {
      font-size: $fontSize;
    }

    &__link {
      font-size: $fontSize;
    }

    &__button {
      width: 100%;
      padding: 7px 12px;
      font-size: $fontSize;
    }
  }
}
</style>

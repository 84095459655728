export const metaTitleTemplate = 'BUDKA';
export const metaDescriptionTemplate = 'Отдах за городом';

export const metaText = {
    default: {
        title: '',
        description: '',
    },
    Home: {
        title: 'BUDKA: Панорамные домики у леса | Отдых в Московской области | Эко-пространство для работы и отдыха',
        description: 'Мы предлагаем незабываемый отдых. Живите в современных домиках, с видом на поля и хвойный лес. Отдохните от городского шума в самом экологически чистом районе МО. Насладитесь велосипедными прогулками, утренними пробежками и звездными ночами на веранде. Отдохните душой и телом.',
    },
    Houses: {
        title: 'Проживание',
        description: 'Забронируйте домик для незабываемого отдыха среди бескрайних полей на опушке леса. Отдохните от городской суеты и насладитесь тишиной веранде',
    },
    Entertainments: {
        title: 'Развлечения',
        description: 'Проведите закат в банном чане на дровах. Закажите натуральных продуктов у месных фермеров. Катайтесь на велосипедах. Играйте бадментон с друзьями и запустите воздушного змея',
    },
    Faq: {
        title: 'Вопросы',
        description: 'Здесь собраны самые популярные вопросв и ответы на них. Как забронирова? Как добраться? Что можно а чего лучше не делать? И многое другое',
    },
    Account: {
        title: 'Личный кабинет',
        description: 'В личном кабинете вы можете узнать о будущих или прошедших поездках, а так же внести доплату за текущее бронирование, внести изменения в данные своего профиля',
    },
    Contacts: {
        title: 'Контакты',
        description: 'До нас вы можете добраться на машине, автобусе и поезде из Москвы. Среднее время в пути 1 час 30 минут. Связаться с нсами можно через указанные соц.сети',
    },
    Offer: {
        title: 'Оферта',
    },
    Policy: {
        title: 'Политика конфиденциальности',
    },
    Booking: {
        title: 'Бронирование',
    },
    Booked: {
        title: 'Успешное бронирование',
    },
    404: {
        title: 'Ошибка 404',
    },
};
